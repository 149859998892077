.flex_container {
    display: flex;
    justify-content: flex-start;
}

.flex_child {
    min-width: 200px;
    margin: 10px;
    padding: 5px;
    border: 1px solid lightgray;
}

.charts {
    height: calc(100vh - 225px);
    padding: 10px;
}

.chart {
    height: 100%;
    width: 100%;
}