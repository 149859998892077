.flex_container {
    display: flex;
    justify-content: flex-start;
}

.flex_child {
    min-width: 200px;
    margin: 10px;
    padding: 5px;
    /* flex: 1; */
    border: 1px solid lightgray;
}

.container {
    padding: 10px;
}

div a {
    display: block;
    margin-bottom: 5px;
}

.fixed_width_btn {
    width: 150px;
    margin-bottom: 5px;
}

.block_quote_container {
    margin: 10px;
    padding: 10px;
    border: 1px solid lightgray;
}

.span_download_link {
    margin: 10px;
    width: fit-content;
}