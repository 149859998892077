.flex_container {
    display: flex;
    justify-content: flex-start;
}

.flex_child {
    min-width: 200px;
    margin: 10px;
    padding: 5px;
    border: 1px solid lightgray;
}

.wrapper {
    height: calc(100vh - 150px);
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    padding: 5px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.charts {
    display: flex;
    flex-direction: column;
}

.chart {
    height: 30%;
    width: 100%;
}

.legend_container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
}

.legend_child {
    flex: 0 0 auto;
    padding: 10px;
    border: 1px solid black;
}